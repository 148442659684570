import axios from 'axios';
import { useEffect, useState } from 'react';

const useFetchProfile = (token: string | undefined) => {
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    picture: '',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (token) {
      const fetchProfile = async () => {
        try {
          const response = await axios.post('/api/one-tap-login', {
            token,
          });
          setProfile(response.data.data);
        } catch (error) {
          setError('Failed to fetch profile');
        } finally {
          setLoading(false);
        }
      };
      fetchProfile();
    } else {
      setError('Token is undefined');
      setLoading(false);
    }
  }, [token]);

  return { profile, loading, error };
};

export default useFetchProfile;
